//
// helper.scss
//
.section {
  padding: 100px 0;
  position: relative;
  @media (max-width: 768px) {
    padding: 60px 0;
  }
}
.section-two {
  padding: 60px 0;
  position: relative;
}
.bg-overlay {
  background-color: $overlay;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.bg-overlay-white {
  background-color: $bg-overlay-white;
}

//Title
.title-heading {
  line-height: 26px;
  .heading {
    font-size: 45px !important;
    letter-spacing: 1px;
    @media (max-width: 768px) {
      font-size: 35px !important;
    }
  }
  .para-desc {
    font-size: 18px;
  }
}
.section-title {
  // position: relative;
  .title {
    letter-spacing: 0.5px;
    font-size: 30px !important;

    @media (max-width: 768px) {
      font-size: 24px !important;
    }
  }
}
.text-shadow-title {
  text-shadow: 2px 0 0 $white, -2px 0 0 $white, 0 4px 0 rgba($white, 0.4), 0 -2px 0 $white, 1px 1px $white,
    -1px -1px 0 $white, 1px -1px 0 $white, -1px 1px 0 $white;
}
.para-desc {
  max-width: 600px;
}
.mt-100 {
  margin-top: 100px;
}
//Shapes
.shape {
  position: absolute;
  pointer-events: none;
  right: 0;
  bottom: -2px;
  left: 0;
  & > svg {
    transform: scale(2);
    width: 100%;
    height: auto;
    transform-origin: top center;
  }

  &.integration-hero {
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    height: 250px;
    background: $white;

    @media (max-width: 768px) {
      height: 140px;
    }

    @media (max-width: 425px) {
      height: 60px;
    }
  }

  @media (max-width: 425px) {
    bottom: -4px;
  }
}

// Text Slider
.text-slider {
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  > ul {
    white-space: nowrap;
  }
  .move-text {
    animation: moveSlideshow 12s linear infinite;
  }
}
@keyframes moveSlideshow {
  100% {
    transform: translateX(-45%);
  }
}

//Mover
.mover {
  animation: mover 1s infinite alternate;
}
@keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(5px);
  }
}

//Feature post placeholder
.feature-posts-placeholder {
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  height: 66.6%;

  @media (max-width: 425px) {
    height: 80%;
  }
}

//Spinner Class
.spin-anything {
  animation: spinner-animation 5s linear infinite;
}

@keyframes spinner-animation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

//SEO Home
.seo-hero-widget {
  position: relative;
  transition: all 0.5s ease;
  &:hover {
    transform: scale(1.05);
    z-index: 9;
  }
}

.swiper-slide {
  display: flex !important;
  align-items: center !important;
}

.partners-mobile{
  display: none;
}

@media (max-width: 992px) {
  .partners-desktop {
    display: none;
  }

  .partners-mobile {
    display: flex !important;
  }
}

.swiper-wrapper {
  align-items: center !important;
  cursor: pointer;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 1.2rem !important;
}

.swiper-button-prev,
.swiper-button-next {
  width: 0 !important;
}

.swiper-container {
  position: static !important;
}

.swiper-button-prev {
  left: 0 !important;
}

.swiper-button-next {
  right: 0 !important;
}

.home-wrapper:before {
  content: "";
  position: absolute;
  bottom: 40rem;
  right: 0rem;
  width: 90rem;
  height: 35rem;
  transform: rotate(78.75deg);
  // background: #f8f9fc !important;
  background: linear-gradient(90deg, rgba(47, 85, 212, 0.3), transparent) !important;
  z-index: 0;

  @media (min-width: 769px) and (max-width: 1024px) {
    right: -20rem;
  }

  @media (min-width: 426px) and (max-width: 768px) {
    right: -15rem;
    width: 70rem;
    height: 25rem;
  }

  @media (max-width: 425px) {
    right: -25rem;
  }
}

@media (min-width: 768px) {
  .margin-top-100 {
    margin-top: 100px;
  }
}
@media (max-width: 768px) {
  .mt-60 {
    margin-top: 60px;
  }
}
